import { IEntityBase } from "../component/interfaces/entity.interface";
import { Address } from "./address.entity";
import { Feature } from "./feature.entity";
import { Passenger } from "./passenger.entity";
import { Service } from "./service.entity";
import { Session } from "./session.entity";

enum BookingType {
    App = "app",
    Dispatch = "dispatch",
    IVR = "ivr",
    Website = "website"
}
enum BookingRate {
    Meter = "meter",
    Flat = "flat"
}
export enum BookingStatus {
    Pickup = "pickup",
    Dropoff = "dropoff",
    Bidding = "bidding",
    Abandoned = "abandoned",
    DriverCancelled = "driver-cancelled",
    PassengerCancelled = "passenger-cancelled",
    AwaitingAccept = "awaiting-accept",
    NotAccepted = "not-accepted",
    DriverEnroute = "driver-enroute",
    DestinationEnroute = "destination-enroute",
    Arrived = "arrived",
    Completed = "completed"
}

export interface Booking extends IEntityBase {
  [key: string]: any;
    passenger: Passenger | string;
    service: Service | string;
    features: Feature[] | string[];
    pickupAddress: Address;
    dropOffAddress: Address;
    estimate: number;
    session: Session;
    code: string;
    type: BookingType;
    rate: BookingRate;
    status: BookingStatus;
    createTime: Date;
    updateTime: Date;
    deletedTime: Date;
}
