import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../..";
import { Row, Col } from "react-bootstrap";
import { useHistory, useLocation } from "react-router";
import { Api, Endpoint } from "../../services/api.service";
import { MapStyle } from "./googleMap/styleMap";
import { add, formatDuration, intervalToDuration } from "date-fns";
import Table from 'react-bootstrap/Table'

import {
  DirectionsRenderer,
  GoogleMap,
  Marker,
  TrafficLayer,
} from "@react-google-maps/api";
import React from "react";
import { useTranslation } from "react-i18next";

interface ServiceList {
   id: string,
   title: string,
   isDefault: boolean,
}

enum Rate {
   Meter = "meter",
   Flat = "flat"
}
enum Status {
   Pickup = "pickup",
   Dropoff = "dropoff",
   Bidding = "bidding",
   Abandoned = "abandoned",
   DriverCancelled = "driver-cancelled",
   PassengerCancelled = "passenger-cancelled",
   AwaitingAccept = "awaiting-accept",
   NotAccepted = "not-accepted",
   DriverEnroute = "driver-enroute",
   DestinationEnroute = "destination-enroute",
   Arrived = "arrived",
   Completed = "completed"
}

interface Driver {
   id: string,
   code: string,
   firstName: string,
   lastName: string,
   gender: string,
   email: string,
   phone: string,
   dateOfBirth: Date,
   businessRegistration: string,
   licenseNumber: string,
   licenseType: string,
   licenseExpiryDate: Date,
   emergencyName: string,
   emergencyEmail: string,
   emergencyPhone: string,
   bankName: string,
   bankAccountNumber: string,
   bankAccountRouting: string,
   bankAccountTitle: string,
   status: string,
   createTime: string,
   updateTime: string,
   deletedTime: string
}

interface Vehicle {
   id: string,
   vin: string,
   plate: string,
   registrationExpiryDate: Date,
   year: string,
   make: string,
   model: string,
   color: string,
   numPassengers: number,
   numLuggage: number,
   ownership: string,
   status: string,
   createTime: string,
   updateTime: string,
   deletedTime: string
}

enum Type {
   Dispatch = "dispatch"
}

interface Area {
   driver: Driver,
   vehicle: Vehicle,
   lat: number,
   lng: number,
   heading: number,
}

interface Gps {
   GpsNearBy: Area[]
}

interface Name {
   firstName: string;
   lastName: string
}

interface location {
   lat: number | undefined,
   lng: number | undefined,
   text: string | undefined,
   area: string | undefined,
}

let directionsService: google.maps.DirectionsService;
const svgMarker = {
  path: "M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0",
  fillColor: 'orange',
  fillOpacity: 1,
  anchor: new google.maps.Point(0,0),
  strokeWeight: 0,
  scale: 0.7
};

const FareStyle = {
  Cell: { border: '2px solid #000000' },
  HeaderRow: { backgroundColor: "#000", color: "#e8f71c", border: "2px solid #000" },
  CellText: { color: "#292929", fontSize: 18 },
}

export function Receipt() {
  const [map, setMap] = useState<google.maps.Map>();
  const [zoom, setZoom] = useState(10);
  const [center, setCenter] = useState<google.maps.LatLng>();
  const [pickupPlace, setPickupPlace] = useState<google.maps.LatLng>();
  const [dropoffPlace, setDropoffPlace] = useState<google.maps.LatLng>();
  const [directions, setDirections] = useState<google.maps.DirectionsResult>();
  const [details, setDetails]: any = useState();
  const [point, setPoint] = useState<google.maps.Point>();
  const [size, setSize] = useState<google.maps.Size>();
  const [track, setTrack] = useState<any>();

  const [gpsTripTrack, setGpsTripTrack] = useState<any>();
  const [currency, setCurrency]: any = useState();
  const [subUnit, setSubUnit]: any = useState();
  const [currCode, setCurrCode]: any = useState();
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const theme = useContext(ThemeContext);
  const [googlePlay, setGooglePlay] = useState("");
  const location = useLocation();
  const history = useHistory();
  const [uuid, setUuid]: any = useState("");
  const { t } = useTranslation("dashboard");


  const onCloseModal = () => {
    setOpen(false);
    history.push("/");
  };


  useEffect(() => {
    const uuid = location.pathname.split("r/")[1];

    if (uuid) {
      console.log("location is defined", uuid);
      setUuid(uuid)
       getReceipt(uuid);
    }
    // map.setOptions({
    //     styles: MapStyle
    //   });
   
    // console.log("Inside Params: ", code);
  }, []);

  const getReceipt = async (code: string) => {
    console.log("Inside Trip Data Before", code);
    const data: any =  await Api.get(Endpoint.Receipt, { uuid: code });
    const gpsTripTrack: any =  await Api.get(Endpoint.TRIP_TRACK, { tripId: data.id });
    console.log("gpsTrack Data",gpsTripTrack)
    if (!data) {
      onOpenModal();
    }
    console.log("Inside Trip Data:", data);

  //  const response = await Api.get<any, any>(Endpoint.GPS_COORDINATES, { data: {vehicleId:data?.session.vehicle.id,driver:data?.session.driver.id,startTime:data?.startTime,endTime:data?.endTime} });
    // console.log('Inside gps response:', response);
    setGpsTripTrack(gpsTripTrack)
    setDetails(data);
    setTrack(data?.gpsArchive);
    setCurrency(data?.setting?.generalCurrency.symbol);
    setSubUnit(data?.setting?.generalCurrency.subunits);
    setCurrCode(data?.setting?.generalCurrency.code);

    if (data) {
      const pickupPlace = new google.maps.LatLng(
        data?.startLatitude,
        data?.startLongitude
      );
      console.log("pick", pickupPlace);
      setPickupPlace(pickupPlace);
      const dropOffPlace = new google.maps.LatLng(
        data?.stopLatitude,
        data?.stopLongitude
      );
      setDropoffPlace(dropOffPlace);
    }
  };

  const onUnmount = React.useCallback(() => {
    setMap(undefined);
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_NAME?.toLowerCase() === "coolride") {
      document.body.style.backgroundColor = "whitesmoke";
      setGooglePlay(`${process.env.REACT_APP_GOOGLE_PLAY_STORE_COOL}`);
    } else {
      document.body.style.backgroundColor = "#edff02";
      setGooglePlay(`${process.env.REACT_APP_GOOGLE_PLAY_STORE_TAXIS}`);
    }
  });

  const onLoad = React.useCallback((map) => {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map);

    directionsService = new google.maps.DirectionsService();

    setTimeout(() => {
      // Sydney
      const DEFAULT_CENTER = new google.maps.LatLng(-33.8688, 151.2093);
      const DEFAULT_POINT = new google.maps.Point(32, 32);
      const DEFAULT_SIZE = new google.maps.Size(64, 64);
      setCenter(DEFAULT_CENTER);
      setPoint(DEFAULT_POINT);
      setSize(DEFAULT_SIZE);
      setZoom(12);
    }, 100);
  }, []);


  function MillisecondsToDuration(e: any) {
    // Hours, minutes and seconds

    // e = Math.floor(e / 1000)

    // e = e/1000;
    var h = Math.floor(e / 3600)
        .toString()
        .padStart(2, "0"),
      m = Math.floor((e % 3600) / 60)
        .toString()
        .padStart(2, "0"),
      s = Math.floor(e % 60)
        .toString()
        .padStart(2, "0");

    return `${h}  :  ${m}  : ${s}  `;
  }

  
  useEffect(() => {
    console.log("Inside oneTimeTwoTime");
    if (!pickupPlace || !dropoffPlace) {
      if (pickupPlace) {
        console.log("pick1", pickupPlace);
        setCenter(pickupPlace);
        setZoom(18);
      }
      if (dropoffPlace) {
        setCenter(dropoffPlace);
        setZoom(18);
      }
      setDirections(undefined);
      return;
    }
    console.log("pickupPlace", pickupPlace, dropoffPlace);
    const departureTime = add(new Date(), { minutes: 10, hours: 5 });
    directionsService?.route(
      {
        origin: pickupPlace,
        destination: dropoffPlace,
        travelMode: google.maps.TravelMode.DRIVING,
        // durationInTraffic: true,
        drivingOptions: { departureTime },
      },
      (result:any, status) => {
        console.log("Inside Direction", status);
        if (status === google.maps.DirectionsStatus.OK) {
          setDirections(result);

          const route = result.routes[0].legs[0];
          if (!route) {
            return;
          }
        }
      }
    );
  }, [pickupPlace, dropoffPlace]);

  const toSubUnit = (value: number): any => {

    const val = (value / subUnit);
    console.log("subUnit", subUnit);

    // return (Math.round(val * subUnit) / subUnit)
    if (subUnit !== 0) {
      const fixed = Math.round((Math.log(subUnit) / Math.log(10)));
      console.log("fixed", fixed);

      return val.toFixed(fixed)
    }

  };

  const getFixedValue = () => {
    const unit = subUnit ? subUnit : 100;
    console.log('This is Values:', Math.round((Math.log(unit) / Math.log(10))))
    return Math.round((Math.log(unit) / Math.log(10)));
  }



  return (
    <>
      {" "}
      <div
        // className={ `${process.env.REACT_APP_NAME}-body`}
        
      >
        <div className="logo alignCenter">
          <Row className={`${process.env.REACT_APP_NAME?.toLowerCase()}-logo`}>
            <Col xs lg="1">
              <img
                className="logoImg"
                src={`/logo/${process.env.REACT_APP_NAME?.toLowerCase()}.png`}
                alt="logo"
                style={{ width: "20rem" }}
              />
            </Col>

            <Col xs lg="1" className="col-mt">
              <h2
                className={`${process.env.REACT_APP_NAME?.toLowerCase()}-heading`}
              >
                {t('dashboard.thanks')}{process.env.REACT_APP_NAME}
              </h2>
            </Col>
          </Row>
        </div>
      
        <div 
        className="table-mt"
         >
          <div
            className="card responsive-card"
          
          >
            <div
              className="card-body text-card"
            >
              <div
                className="card"
              >
                <p className={`${process.env.REACT_APP_NAME?.toLowerCase()}-p`}>
                {t('dashboard.downloadApp')}
                </p>
                <p
                  style={{ marginBottom: "100px" }}
                  className={`${process.env.REACT_APP_NAME?.toLowerCase()}-p`}
                >
                 {t('dashboard.appFeatures')}</p>
                <a target="_blank" href={googlePlay}>
                  <img
                    className="logoImg google-pic"
                    src="/download-google-play-icon-27.png"
                    alt="logo"
                  />
                </a>
                <img
                  className="logoImg apple-pic"
                  src="/apple-app-store-icon-28.jpg"
                  alt="logo"
                  
                />
              </div>
            </div>
          </div>
        </div>

        {/*  Google Map */}

       {uuid? <div className="table-mt mt-100" >
          <div
            className="card responsive-card card-align card-wid"
            // style={{ width: "60rem" }}
          >
            <div
              className="card-body card-map-body" 
            >
              <div
                className="card card-height"
              >
               
                <GoogleMap
                  mapContainerStyle={{ height: "600px", width: "100%",border: "5px solid white",
                  borderRadius: "15px" }}
                  center={center}
                  zoom={zoom}
                  onLoad={onLoad}
                  onUnmount={onUnmount}
                  // options={{ styles: MapStyle as any }}
                >
                  {/* {details&&<Marker position={new google.maps.LatLng(details.startLatitude, details.startLongitude)} />} */}
                  {!directions && pickupPlace && (
                    <Marker position={pickupPlace} />
                  )}
                  {directions && (
                    <DirectionsRenderer options={{ directions }} />
                  )}
                  {directions && <TrafficLayer />}
                  {track
                    ? track.map((t: any) => {
                        return (
                          <Marker
                            position={
                              new google.maps.LatLng(t.latitude, t.longitude)
                            }
                          />
                        );
                      })
                    : ""}

              {gpsTripTrack ?
              gpsTripTrack.map((t: any) => {
                return <Marker icon ={svgMarker} position={new google.maps.LatLng(t.latitude, t.longitude)} />
              }) : ""}
                </GoogleMap>
                {/* </div> */}
              </div>
              <div className={`row ${process.env.REACT_APP_NAME?.toLowerCase()} m-200`}
                  >
  
                <div className="col-12 mt-300"
                 >
 
 <div> <span
                        className="span-design"
                        >
                          {t("receipt.tripDetails")}
                        </span>
                      <div className="table-responsive" style={{display:"grid",marginTop:"20px"}}> 
                        <table className="table table-bordered">
                            <thead>
                                <tr style={ FareStyle.HeaderRow }>
                                    <th style={ FareStyle.Cell } scope="col">Name</th>
                                    <th style={ FareStyle.Cell } scope="col">Value</th>
                                   
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={ FareStyle.Cell }>
                                    <td style={{ border: "2px solid #000",  }}>CODE</td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>  {details?.code}</td>
                                </tr>
                                <tr style={ FareStyle.Cell }>
                                    <td style={{ border: "2px solid #000",  }}>DRIVER</td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>{details?.session?.driver?.firstName}</td>
                                    
                                </tr>
                                <tr style={ FareStyle.Cell }>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>DRIVER ABN</td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>{details?.session?.driver?.businessRegistration}</td>
                                    
                                </tr>
                                <tr style={ FareStyle.Cell }>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}> VEHICLE PLATE</td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>{details?.session?.vehicle?.plate}</td>
                              
                                </tr>
                                <tr style={ FareStyle.Cell }>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}> VEHICLE MODEL</td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>{details?.session?.vehicle?.model}</td>
                                 
                                </tr>
                                <tr style={ FareStyle.Cell }>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}> VEHICLE COLOR</td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }} colSpan={1}>{details?.session?.vehicle?.color}<br/> </td>
                               
                                </tr>

                                <tr style={ FareStyle.Cell }>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}> VEHICLE MAKE</td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }} colSpan={1}>{details?.session?.vehicle?.make}<br/> </td>
                               
                                </tr>

                                <tr style={ FareStyle.Cell }>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}> VEHICLE YEAR</td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }} colSpan={1}>{details?.session?.vehicle?.year}<br/> </td>
                               
                                </tr>
                               
                            </tbody>
                        </table>
                    </div>
                    </div>


                    <div className="mt-50"> <span
                        className="span-design"
                        >
                           {t("receipt.fareDetails")}
                        </span>
                      <div className="table-responsive" style={{display:"grid",marginTop:"20px"}}> 
                        <table className="table table-bordered">
                            <thead>
                                <tr style={ FareStyle.HeaderRow }>
                                    <th style={ FareStyle.Cell } scope="col">Name</th>
                                    <th style={ FareStyle.Cell } scope="col">Value</th>
                                   
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={ FareStyle.Cell }>
                                    <td style={{ border: "2px solid #000",  }}> DISTANCE</td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}> {!details?.distance ? "" : details?.distance}</td>
                                </tr>
                                <tr style={ FareStyle.Cell }>
                                    <td style={{ border: "2px solid #000",  }}> RIDE TIME</td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>{details?.rideTime ?MillisecondsToDuration(details?.rideTime):""}</td>
                                    
                                </tr>
                                <tr style={ FareStyle.Cell }>
                                    <td style={{ border: "2px solid #000",  }}> WAIT TIME</td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>  {details?.waitTime?MillisecondsToDuration(details?.waitTime):""}</td>
                                    
                                </tr>
                             
                               
                            </tbody>
                        </table>
                    </div>
                    </div>


                    <div className="mt-50"> <span
                        className="span-design"
                        >
                           {t("receipt.fareDetails")}
                        </span>
                      <div className="table-responsive" style={{display:"grid",marginTop:"20px"}}> 
                        <table className="table table-bordered">
                            <thead>
                                <tr style={ FareStyle.HeaderRow }>
                                    <th style={ FareStyle.Cell } scope="col">Name</th>
                                    <th style={ FareStyle.Cell } scope="col">Value</th>
                                   
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={ FareStyle.Cell }>
                                    <td style={{ border: "2px solid #000",  }}>  DISTANCE CHARGE</td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>    {currency} {details?.distanceCharge ?  toSubUnit(details?.distanceCharge): `${currency?currency:""} ` + (0).toFixed(getFixedValue()) }</td>
                                </tr>
                                <tr style={ FareStyle.Cell }>
                                    <td style={{ border: "2px solid #000",  }}>   START CHARGE</td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>  {currency} {details?.fare?.startCharge? toSubUnit(details?.fare?.startCharge): `${currency?currency:""} ` + (0).toFixed(getFixedValue())}</td>
                                    
                                </tr>
                                <tr style={ FareStyle.Cell }>
                                    <td style={{ border: "2px solid #000",  }}> BOOKING CHARGE</td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>   {currency} {details?.bookingCharge?toSubUnit(details?.bookingCharge): `${currency?currency:""} ` + (0).toFixed(getFixedValue())}</td>
                                    
                                </tr>

                                <tr style={ FareStyle.Cell }>
                                    <td style={{ border: "2px solid #000",  }}>  EXTRA CHARGE</td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>     {currency} {details?.extraCharge?toSubUnit(details?.extraCharge): `${currency?currency:""} ` + (0).toFixed(getFixedValue())}
</td>
                                    
                                </tr>

                                <tr style={ FareStyle.Cell }>
                                    <td style={{ border: "2px solid #000",  }}>   TAX

</td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>       {currency} {details?.tax?toSubUnit(details?.tax): `${currency?currency:""} ` + (0).toFixed(getFixedValue())}</td>
                                    
                                </tr>
                                <tr style={ FareStyle.Cell }>
                                    <td style={{ border: "2px solid #000",  }}>   TOLLS</td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>    {currency} {details?.tolls?toSubUnit(details?.tolls): `${currency?currency:""} ` + (0).toFixed(getFixedValue())}</td>
                                    
                                </tr>

                                <tr style={ FareStyle.Cell }>
                                    <td style={{ border: "2px solid #000",  }}>  CC CHARGE</td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}> {currency} {details?.ccCharge?toSubUnit(details?.ccCharge): `${currency?currency:""} ` + (0).toFixed(getFixedValue())}</td>
                                    
                                </tr>

                                <tr style={ FareStyle.Cell }>
                                    <td style={{ border: "2px solid #000",  }}>  TIP</td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>              {currency} {details?.tip?toSubUnit(details?.tip): `${currency?currency:""} ` + (0).toFixed(getFixedValue())}</td>
                                    
                                </tr>

                                <tr style={ FareStyle.Cell }>
                                    <td style={{ border: "2px solid #000",  }}>  WAIT CHARGE
</td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>                     {currency} {details?.waitCharge?toSubUnit(details?.waitCharge): `${currency?currency:""} ` + (0).toFixed(getFixedValue())}</td>
                                    
                                </tr>


                                <tr style={ FareStyle.Cell }>
                                    <td style={{ border: "2px solid #000",  }}>  LEVY
</td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>                       {currency} {details?.levy?toSubUnit(details?.levy) : `${currency?currency:""} ` + (0).toFixed(getFixedValue())}</td>
                                    
                                </tr>

                               
                            </tbody>
                        </table>
                    </div>
                    </div>

                    <div className="mt-50"> <span
                        className="span-design"
                        >
                           {t("receipt.total")}
                        </span>
                      <div className="table-responsive" style={{display:"grid",marginTop:"20px"}}> 
                        <table className="table table-bordered">
                            <thead>
                                <tr style={ FareStyle.HeaderRow }>
                                    <th style={ FareStyle.Cell } scope="col">Name</th>
                                    <th style={ FareStyle.Cell } scope="col">Value</th>
                                   
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={ FareStyle.Cell }>
                                    <td style={{ border: "2px solid #000",  }}>  TOTAL</td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>     {currency} {details?.totalFare?toSubUnit(details?.totalFare): `${currency?currency:""} ` + (0).toFixed(getFixedValue())}</td>
                                </tr>
                                <tr style={ FareStyle.Cell }>
                                    <td style={{ border: "2px solid #000",  }}>   PAID VIA
</td>
                                    <td style={{ ...FareStyle.Cell, ...FareStyle.CellText }}>        {details?.paidBy}</td>
                                    
                                </tr>
                            
                             
                               
                            </tbody>
                        </table>
                    </div>
                    </div>

                 
                </div>
             
             
             </div>
            </div>
          </div>
        </div>:""}
      </div>
      {/* <footer style={{ background: "white" }}></footer> */}
    </>
  );
}
