import { useContext, useEffect, useRef, useState } from "react";
import { ThemeContext } from "../..";
import { Row, Col, Container } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router";
import { Api, Endpoint } from "../../services/api.service";
import { MapStyle } from "../receipt/googleMap/styleMap";
import { add, formatDuration, intervalToDuration } from "date-fns";
import Table from "react-bootstrap/Table";
import car from "../../img/car.svg";

import {
  Autocomplete,
  DirectionsRenderer,
  GoogleMap,
  Marker,
  TrafficLayer,
} from "@react-google-maps/api";
import React from "react";
import { useTranslation } from "react-i18next";
import { Passenger } from "../../entities/passenger.entity";
import { Zone } from "../../entities/zone.entity";
import { Currency } from "../../entities/currency.entity";
import { Booking } from "../../entities/booking.entity";
import QRCode from "react-qr-code";

interface ServiceList {
  id: string;
  title: string;
  isDefault: boolean;
}

enum Rate {
  Meter = "meter",
  Flat = "flat",
}
enum Status {
  Pickup = "pickup",
  Dropoff = "dropoff",
  Bidding = "bidding",
  Abandoned = "abandoned",
  DriverCancelled = "driver-cancelled",
  PassengerCancelled = "passenger-cancelled",
  AwaitingAccept = "awaiting-accept",
  NotAccepted = "not-accepted",
  DriverEnroute = "driver-enroute",
  DestinationEnroute = "destination-enroute",
  Arrived = "arrived",
  Completed = "completed",
}

interface Driver {
  id: string;
  code: string;
  firstName: string;
  lastName: string;
  gender: string;
  email: string;
  phone: string;
  dateOfBirth: Date;
  businessRegistration: string;
  licenseNumber: string;
  licenseType: string;
  licenseExpiryDate: Date;
  emergencyName: string;
  emergencyEmail: string;
  emergencyPhone: string;
  bankName: string;
  bankAccountNumber: string;
  bankAccountRouting: string;
  bankAccountTitle: string;
  status: string;
  createTime: string;
  updateTime: string;
  deletedTime: string;
}

interface Vehicle {
  id: string;
  vin: string;
  plate: string;
  registrationExpiryDate: Date;
  year: string;
  make: string;
  model: string;
  color: string;
  numPassengers: number;
  numLuggage: number;
  ownership: string;
  status: string;
  createTime: string;
  updateTime: string;
  deletedTime: string;
}

enum Type {
  Dispatch = "dispatch",
}

interface Area {
  driver: Driver;
  vehicle: Vehicle;
  lat: number;
  lng: number;
  heading: number;
}

interface Gps {
  GpsNearBy: Area[];
}

interface Name {
  firstName: string;
  lastName: string;
}

interface location {
  lat: number | undefined;
  lng: number | undefined;
  text: string | undefined;
  area: string | undefined;
}

let directionsService: google.maps.DirectionsService;
const svgMarker = {
  path: "M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0",
  fillColor: "orange",
  fillOpacity: 1,
  anchor: new google.maps.Point(0, 0),
  strokeWeight: 0,
  scale: 0.7,
};

const FareStyle = {
  Cell: { border: "2px solid #000000" },
  HeaderRow: {
    backgroundColor: "#000",
    color: "#e8f71c",
    border: "2px solid #000",
  },
  CellText: { color: "#292929", fontSize: 18 },
};

interface location {
  lat: number | undefined;
  lng: number | undefined;
  text: string | undefined;
  area: string | undefined;
}

export function HotspotReceipt(props: any) {
  const [map, setMap] = useState<google.maps.Map>();
  const [zoom, setZoom] = useState(10);
  const [center, setCenter] = useState<google.maps.LatLng>();
  const [pickupPlace, setPickupPlace] =
    useState<google.maps.places.PlaceResult>();
  const [dropoffPlace, setDropoffPlace] =
    useState<google.maps.places.PlaceResult>();
  const [directions, setDirections] = useState<google.maps.DirectionsResult>();
  const [details, setDetails]: any = useState();
  const [point, setPoint] = useState<google.maps.Point>();
  const [size, setSize] = useState<google.maps.Size>();
  const [track, setTrack] = useState<any>();
  const [pickupPlaceName, setPickupPlaceName] = useState<any>();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const [gpsTripTrack, setGpsTripTrack] = useState<any>();
  const [currency, setCurrency]: any = useState();
  const [subUnit, setSubUnit]: any = useState();
  const [currCode, setCurrCode]: any = useState();
  const onOpenModal = () => setOpen(true);
  const theme = useContext(ThemeContext);
  const [googlePlay, setGooglePlay] = useState("");
  const location = useLocation();
  const [uuid, setUuid]: any = useState("");
  const { t } = useTranslation("dashboard");
  const params: any = useParams();
  let pickupAutocompleteRef = useRef<Autocomplete>(null);
  let dropoffAutocompleteRef = useRef<Autocomplete>(null);
  let history = useHistory();

  const [dropOffMarker, setDropOffMarker] = useState<boolean>(false);
  const [pickupMarker, setPickupMarker] = useState<boolean>(false);
  const [generalCurrency, setGeneralCurrency] = useState<Currency>();
  const [zone, setZone] = useState<Zone>();
  const [pickupPlaceFormatted, setPickupPlaceFormatted] = useState<location>();
  const [dropoffPlaceFormatted, setDropoffPlaceFormatted] =
    useState<location>();

  const [distance, setDistance] = useState<number>();
  const [duration, setDuration] = useState<number>();
  const [trafficDuration, setTrafficDuration] = useState<number>();
  const [estimate, setEstimate] = useState<number>();
  const [pickupLatLng, setPickupLatLng] = useState<google.maps.LatLng>();
  const [options, setOptions] = useState<any[]>([]);
  const [findArea, setFindArea] = useState<Gps>();
  const [serviceId, setServiceId] = useState<string>("");
  const [dropoffLatLng, setDropoffLatLng] = useState<google.maps.LatLng>();
  const [sendData, setSendData] = useState<boolean>(true);
  const [email, setEmail] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [name, setName] = useState<Name>({ firstName: "", lastName: "" });
  const [passenger, setPassenger] = useState<Passenger>();
  const [open, setOpen] = useState(false);
  const [verificationCode, setVerficationCode] = useState<string>();

  //   const onCloseModal = () => {
  //     setOpen(false);
  //     history.push("/");
  //   };

  useEffect(() => {
    console.log("params", params);
    const uuid = params.id;

    if (uuid) {
      console.log("location is defined", uuid);
      setUuid(uuid);
      getHotspot(uuid);
    }
    // map.setOptions({
    //     styles: MapStyle
    //   });

    // console.log("Inside Params: ", code);
  }, []);

  const getHotspot = async (code: string) => {
    console.log("Inside Trip Data Before", code);

    const data: any = await Api.get(Endpoint.HOTSPOT, { id: code });
    console.log("data hotspot", data);
    if (data.address) {
      //  pickupAutocompleteRef.current.state.autocomplete.
      setPickupPlaceName(data.address.text);
    } else {
      setPickupPlaceName("");
    }
  };

  const onLoad = React.useCallback(async (map) => {
    console.log("pickupPlace", pickupPlaceName);
    if (!isLoaded) {
      const uuid = params.id;
      const data: any = await Api.get(Endpoint.HOTSPOT, { id: uuid });
      const bounds = new window.google.maps.LatLngBounds();
      const location = new google.maps.LatLng(
        data.address.lat,
        data.address.lng
      );
      map.fitBounds(bounds);
      setMap(map);

      var request = {
        location: location,
        radius: 500,
        fields: ["name", "geometry", "address_component", "formatted_address"],
      };

      var service = new google.maps.places.PlacesService(map);
      service.nearbySearch(request, function (results, status) {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          //   const result = results[0];
          var detailRequest = {
            placeId: results[0].place_id as string,
            fields: [
              "name",
              "geometry",
              "address_component",
              "formatted_address",
            ],
          };
          service.getDetails(detailRequest, (place, status) => {
            if (status == google.maps.places.PlacesServiceStatus.OK) {
              // createMarker(place);
              console.log("place", place);
              setPickupPlace(place);
              const areaTypes = [
                "locality",
                "sublocality_level_1",
                "sublocality",
                "political",
              ];
              const pickupData = place;
              if (!pickupData) {
                return;
              }
              const area = place?.address_components?.find((component: any) => {
                for (let areaType of areaTypes) {
                  if (component.types.includes(areaType)) {
                    return true;
                  }
                }
                return false;
              });

              console.log("Area", area);

              setPickupPlaceFormatted({
                lat: place?.geometry?.location.lat(),
                lng: place?.geometry?.location.lng(),
                text: place?.formatted_address,
                area: area?.long_name,
              });
            }
          });
        }
      });
    }
    //  setTimeout(() => {
    //     // Sydney
    //  const DEFAULT_CENTER = new google.maps.LatLng(-33.8688, 151.2093);
    //     const DEFAULT_POINT = new google.maps.Point(32, 32);
    //     const DEFAULT_SIZE = new google.maps.Size(64, 64);

    //     setCenter(DEFAULT_CENTER);
    //     setPoint(DEFAULT_POINT)
    //     setSize(DEFAULT_SIZE)
    //     setZoom(12);
    //  }, 100);
    //  const coordinates = new google.maps.LatLng()

    directionsService = new google.maps.DirectionsService();

    if (pickupAutocompleteRef.current?.state?.autocomplete) {
      pickupAutocompleteRef.current.state.autocomplete.addListener(
        "place_changed",
        () => onAddressSelect("pickup")
      );
    }
    if (dropoffAutocompleteRef.current?.state?.autocomplete) {
      dropoffAutocompleteRef.current.state.autocomplete.addListener(
        "place_changed",
        () => onAddressSelect("dropoff")
      );
    }
  }, []);

  const onAddressSelect = (type: "dropoff" | "pickup") => {
    const ref =
      type === "dropoff" ? dropoffAutocompleteRef : pickupAutocompleteRef;
    const areaTypes = [
      "locality",
      "sublocality_level_1",
      "sublocality",
      "political",
    ];
    const autocomplete:
      | google.maps.places.Autocomplete
      | null
      | undefined
      | any = ref.current?.state.autocomplete;
    if (!autocomplete) {
      return;
    }
    const area = autocomplete
      .getPlace()
      .address_components.find((component: any) => {
        for (let areaType of areaTypes) {
          if (component.types.includes(areaType)) {
            return true;
          }
        }
        return false;
      });

    let key: string = Object.keys(autocomplete.gm_accessors_.place)[0];
    const data = google.maps.places.Autocomplete;

    type === "dropoff"
      ? setDropoffPlaceFormatted({
          lat: autocomplete.getPlace().geometry?.location.lat(),
          lng: autocomplete.getPlace().geometry?.location.lng(),
          text: autocomplete.getPlace().formatted_address,
          area: area.long_name,
        })
      : setPickupPlaceFormatted({
          lat: autocomplete.getPlace().geometry?.location.lat(),
          lng: autocomplete.getPlace().geometry?.location.lng(),
          text: autocomplete.getPlace().formatted_address,
          area: area.long_name,
        });

    const setter = type === "dropoff" ? setDropoffPlace : setPickupPlace;
    setter(autocomplete.getPlace());

    if (type === "dropoff") {
      setDropoffPlace(autocomplete.getPlace());
      setDropOffMarker(false);
    }
    if (type === "pickup") {
      console.log("pickup auto", autocomplete.getPlace());
      setPickupPlace(autocomplete.getPlace());
      setPickupMarker(false);
    }
  };

  //   useEffect(() => {
  //     if (process.env.REACT_APP_NAME?.toLowerCase() === "coolride") {
  //       document.body.style.backgroundColor = "whitesmoke";
  //       setGooglePlay(`${process.env.REACT_APP_GOOGLE_PLAY_STORE_COOL}`);
  //     } else {
  //       document.body.style.backgroundColor = "#edff02";
  //       setGooglePlay(`${process.env.REACT_APP_GOOGLE_PLAY_STORE_TAXIS}`);
  //     }
  //   });

  //   const toSubUnit = (value: number): any => {
  //     console.log("code", currCode, subUnit);
  //     console.log("stats value", value);
  //     value = value / subUnit;
  //     const result =
  //       Math.round((value + Number.EPSILON) * Math.pow(10, subUnit - 1)) /
  //       Math.pow(10, subUnit - 1);
  //     return result;
  //   };

  useEffect(() => {
    console.log("Inside oneTimeTwoTime");
    if (!pickupPlace || !dropoffPlace) {
      if (pickupPlace) {
        console.log("pick1", pickupPlace);
        //   const pickupCenter = new google.maps.LatLng(pickupPlace.geometry?.location?.lat() as number, pickupPlace.geometry?.location?.lng());
        setCenter(pickupPlace?.geometry?.location);
        setZoom(18);
      }
      if (dropoffPlace) {
        // const dropoffCenter = new google.maps.LatLng(dropoffPlace.geometry?.location?.lat() as number, dropoffPlace.geometry?.location?.lng());

        setCenter(dropoffPlace?.geometry?.location);
        setZoom(18);
      }
      setDirections(undefined);
      return;
    }
    console.log("pickupPlace", pickupPlace, dropoffPlace);
    const departureTime = add(new Date(), { minutes: 10, hours: 5 });

    directionsService?.route(
      {
        origin: pickupPlace?.geometry?.location,
        destination: dropoffPlace?.geometry?.location,
        travelMode: google.maps.TravelMode.DRIVING,
        // durationInTraffic: true,
        drivingOptions: { departureTime },
      },
      (result: any, status) => {
        console.log("Inside Direction", status);
        if (status === google.maps.DirectionsStatus.OK) {
          setDirections(result);

          const route = result.routes[0].legs[0];
          if (!route) {
            return;
          }
        }
      }
    );
  }, [pickupPlace, dropoffPlace]);

  const getZoneByLocation = async (
    pickupLocation: google.maps.places.PlaceResult
  ) => {
    console.log("pickupPlace", pickupLocation.geometry?.location);
    if (pickupLocation) {
      console.log(
        "pickupPlaceLatlng",
        pickupLocation.geometry?.location?.lat(),
        pickupLocation.geometry?.location?.lng()
      );
      const response: any = await Api.post(Endpoint.HOTSPOT_ZONE, {
        lat: pickupLocation?.geometry?.location?.lat(),
        lng: pickupLocation?.geometry?.location?.lng(),
      });
      console.log("Zone Data:", response);
      setGeneralCurrency(response.generalCurrency);
      setZone(response);
    }
    try {
    } catch (error) {
      console.log("Error Inside Zone:", error);
    }
  };

  const onUnmount = React.useCallback(() => {
    setMap(undefined);
  }, []);

  const findInArea = async () => {
    try {
      if (pickupPlace) {
        if (sendData === true) {
          const response: any = await Api.post(Endpoint.DRIVER_AREA, {
            latitude: pickupPlace?.geometry?.location?.lat().toString(),
            longitude: pickupPlace?.geometry?.location?.lng().toString(),
            serviceId: serviceId.toString(),
          });
          setFindArea(response?.data);
          setSendData(false);
        } else {
          setInterval(async function () {
            const response: any = await Api.post(Endpoint.DRIVER_AREA, {
              latitude: pickupPlace?.geometry?.location?.lat().toString(),
              longitude: pickupPlace?.geometry?.location?.lng().toString(),
              serviceId: serviceId.toString(),
            });
            setFindArea(response?.data);
          }, 30000);
        }
      } else {
        console.log("this api will not run until pickupPlace location filled");
      }
    } catch (err) {
      console.log("Failed to find in area", err);
    }
  };

  useEffect(() => {
    findInArea();
    if (pickupPlace) {
      getZoneByLocation(pickupPlace);
    }
  }, [pickupPlace]);

  const loadEstimate = async () => {
    console.log("Trafic Time:", trafficDuration);
    const response: any = await Api.post(Endpoint.FARE_ESTIMATE, {
      lat: pickupPlace?.geometry?.location?.lat(),
      lng: pickupPlace?.geometry?.location?.lng(),
      distance,
      duration,
      traffic: trafficDuration,
      serviceId,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone.toString(),
    });

    setEstimate(response);
  };

  useEffect(() => {
    if (!pickupPlace || !distance || !duration || !serviceId === undefined) {
      console.log(
        `incomplete ${pickupPlace} ${distance} ${duration} ${trafficDuration} ${serviceId}`
      );
      return;
    }
    console.log("complete:", estimate);
    loadEstimate();
  }, [pickupPlace, distance, duration, trafficDuration, serviceId, estimate]);

  useEffect(() => {
    refresh();
  }, [serviceId]);

  const refresh = async () => {
    try {
      const response: any = await Api.get(Endpoint.SERVICE_LIST);
      console.log("response servuce", response);
      const data: ServiceList[] = response.map((item: any) => ({
        id: item.id,
        title: item.title,
        isDefault: item.isDefault,
      }));

      for (let i = 0; i < data.length; i++) {
        console.log(data[i].isDefault);

        if (data[i].isDefault === true) {
          let temp;
          temp = data[0];
          data[0] = data[i];
          data[i] = temp;
          setServiceId(data[i].id);
        }
      }
      console.log("service List", data);
      setOptions(data);
    } catch (err) {
      console.log("Failed to load fleet list", err);
    }
  };
  const onChange = (e: any) => {
    console.log(e.target.value);
    setServiceId(e.target.value);
  };

  useEffect(() => {
    if (!pickupPlace || !dropoffPlace) {
      if (pickupPlace) {
        setCenter(pickupPlace.geometry?.location);
        setZoom(18);
      }
      if (dropoffPlace) {
        setCenter(dropoffPlace.geometry?.location);
        setZoom(18);
      }
      // setDirections(undefined);
      // return;
    }

    // Set departure time to 10 minues from now
    const departureTime = add(new Date(), { minutes: 10, hours: 5 });
    if (directionsService) {
      directionsService.route(
        {
          origin:
            pickupMarker === true
              ? pickupLatLng
              : pickupPlace?.geometry?.location,
          destination:
            dropOffMarker === true
              ? dropoffLatLng
              : dropoffPlace?.geometry?.location,
          travelMode: google.maps.TravelMode.DRIVING,
          durationInTraffic: true,
          drivingOptions: { departureTime },
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            setDirections(result);

            const route = result.routes[0].legs[0];
            if (!route) {
              return;
            }
            setDistance(route.distance.value);
            setDuration(
              route.duration_in_traffic
                ? route.duration_in_traffic.value
                : route.duration.value
            );

            const trafficTime = route.duration_in_traffic
              ? route.duration_in_traffic.value - route.duration.value
              : 0;
            console.log("traffice Time,", trafficTime);
            if (trafficTime > 0) setTrafficDuration(trafficTime);
            else {
              setTrafficDuration(0);
            }
          }
        }
      );
    }
  }, [pickupPlace, dropoffPlace, serviceId]);

  const distanceText = (): string => {
    if (!distance) {
      return "N/A";
    }
    return `${roundTo(distance / 1000)} km`;
  };

  const durationText = (): string => {
    if (!duration) {
      return "N/A";
    }
    const epoch = new Date(0);
    const secondsAfterEpoch = new Date(duration * 1000);
    const durationObj = intervalToDuration({
      start: epoch,
      end: secondsAfterEpoch,
    });
    return formatDuration(durationObj, { format: ["hours", "minutes"] });
  };

  const trafficText = (): string => {
    if (!trafficDuration) {
      return "N/A";
    }
    const epoch = new Date(0);
    const secondsAfterEpoch = new Date(trafficDuration * 1000);
    const durationObj = intervalToDuration({
      start: epoch,
      end: secondsAfterEpoch,
    });
    return formatDuration(durationObj, { format: ["hours", "minutes"] });
  };

  const estimateText = (): string => {
    if (!estimate || isNaN(estimate)) {
      return "...";
    }

    return estimate.toFixed(2).toString();
  };

  const roundTo = (val: number): number => {
    return Math.round((val + Number.EPSILON) * 100) / 100;
  };

  const onHandleVerfication = async (e: any) => {
    e.preventDefault();
    // console.log("verfication input",verificationCode)

    if (verificationCode == passenger?.verificationCode) {
      const data: Booking = await Api.post(Endpoint.BOOKING, {
        peerId: "123e4567-e89b-12d3-a456-426614174000",
        firstName: name.firstName,
        lastName: name.lastName,
        service: serviceId,
        pickupAddress: {
          lat: pickupPlaceFormatted?.lat,
          lng: pickupPlaceFormatted?.lng,
          text: pickupPlaceFormatted?.text,
          area: pickupPlaceFormatted?.area,
        },
        dropOffAddress: {
          lat: dropoffPlaceFormatted?.lat,
          lng: dropoffPlaceFormatted?.lng,
          text: dropoffPlaceFormatted?.text,
          area: dropoffPlaceFormatted?.area,
        },
        email: email,
        phone: phone,
        distance: distance,
        duration: durationText(),
        status: Status.Dropoff,
        zone: zone,
        estimate: estimate,
        rate: Rate.Meter,
        type: Type.Dispatch,
      });
      if (data) {
        console.log("Inside Booking:", data);
        setOpen(false);
        history.push(`/track/${data.code}`);
      }
    } else {
      alert("Wrong OTP Code");
    }
  };

  const onHandleClick = async (e: any) => {
    e.preventDefault();

    const response: any = await Api.post(Endpoint.OTP_LINK, {
      phone,
      name: name.firstName,
      lastName: name.lastName,
      email,
      appName: `${process.env.REACT_APP_NAME?.toUpperCase()}`,
    });

    console.log("phone response", response);
    let passengerData: any;
    if (response?.newPassenger || response?.passenger) {
      // setNewPassenger(response.data?.newPassenger)
      passengerData = response?.newPassenger
        ? response?.newPassenger
        : response?.passenger;
      setPassenger(passengerData);
      console.log("phone passenger", passengerData);
      setOpen(true);
    } else {
      console.error("no passenger data received ");
    }

    //  const {data}: Booking = await Api.post(Endpoint.BOOKING, {
    //     peerId: '123e4567-e89b-12d3-a456-426614174000',
    //     firstName: name.firstName,
    //     lastName: name.lastName,
    //     service: serviceId,
    //     pickupAddress: { lat: pickupPlaceFormatted?.lat, lng: pickupPlaceFormatted?.lng, text: pickupPlaceFormatted?.text,area:pickupPlaceFormatted?.area },
    //     dropOffAddress: { lat: dropoffPlaceFormatted?.lat, lng: dropoffPlaceFormatted?.lng, text: dropoffPlaceFormatted?.text, area:dropoffPlaceFormatted?.area },
    //     email: email,
    //     phone: phone,
    //     distance: distance,
    //     duration: durationText(),
    //     status: Status.Dropoff,
    //     zone:zone,
    //     estimate: estimate,
    //     rate: Rate.Meter,
    //     type: Type.Dispatch
    //  })
    //  console.log('Inside Booking:', data);
    //  history.push(`/track/${data.code}`)
  };

  const onChangeName = (e: any) => {
    let temp = e.target.value;
    let first = temp.split(" ");
    let last;
    first = first[0];
    if (temp.split(" ").slice(-1).join(" ") === first) {
      last = "";
    } else {
      last = temp.split(" ").slice(-1).join(" ");
    }
    setName({
      firstName: first,
      lastName: last,
    });
  };

  return (
    <>
      {" "}
      <div
      className={ `${process.env.REACT_APP_NAME}-body`}
      >
      <div style={{background:"white"}}>


        <div className="container curved" style={{background:"#3b41ff",marginTop: "62px"}}>
        <div style={{
            textAlign: "center",
            zIndex: 1,
            position: "absolute",
            marginLeft: "auto",
            // marignRight: "auto",
            left: "0",
            right: "0",
            marginTop: "auto",
            top: "-55px",
        }}>
        <img
                className="logoImg"
                src={`/logo/${process.env.REACT_APP_NAME?.toLowerCase()}.png`}
                alt="logo"
                style={{ width: "20rem" }}
              />
            </div>
            <div style={{textAlign:"center", padding:"117px"}}>
            <QRCode size={window.innerWidth > 768?500:200}  value={`Hello World`} />
            </div>
            <div>
                <h1 style={{textAlign:"center"}}>Hello World</h1>
            </div>

            
           
        </div>

        {/* {uuid ? (
          <Container style={{ margin: "100px" }}>
            <Row
              className={!open ? "form-align-center" : "form-align-spacearound"}
              style={{ display: "flex" }}
            >
             
            </Row>
          </Container>
        ) : (
          ""
        )} */}
        
      </div>
      </div>
    </>
  );
}
