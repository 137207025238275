import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import enDashboard from '../locales/en/dashboard.json';
import esDashboard from '../locales/es/dashboard.json';
const language = ['en', 'es'];

const options = {
  order: ['querystring', 'navigator'],
  lookupQuerystring: 'lng'
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    supportedLngs:language,
    detection:options,
    whitelist: language,
    debug: true,
    resources: {
      en: {
        dashboard: enDashboard,
      },
      es: {
        dashboard: esDashboard,
      },
    }, 
  });

export default i18n;
