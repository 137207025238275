import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Route } from "react-router-dom";
// import './css/taxisplus.css';
import { Receipt } from "./component/receipt/receipt";
import { LoadScript } from "@react-google-maps/api";
import "./services/i18n";
import { Hotspot } from "./component/hotspot/hotspot";
import { Tracking } from "./component/track/track";
import { HotspotReceipt } from "./component/hotspot/hotspotReceipt";

export const ThemeContext = React.createContext(process.env.REACT_APP_THEME);

// const coolconnectFavicon = require(`./css/img/${process.env.REACT_APP_COOL_RIDE_FAVICON}`);
// const taxisPlusFavicon = require(`./css/img/${process.env.REACT_APP_TAXIS_PLUS_FAVICON}`);
export function App() {
  const [theme, setTheme] = useState({ value: process.env.REACT_APP_THEME });

  useEffect(()=>{

    const fileName = process.env.REACT_APP_NAME?.toLowerCase()

    const style = require(`./css/${fileName}.css`)

  },[])
 

  return (
    <ThemeContext.Provider value={theme.value}>
      <Router>
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/react-draft-wysiwyg@1.12.3/dist/react-draft-wysiwyg.css"
        />
        <>
          {/* <Route exact path='/' component={RideCode} /> */}
          <Route exact path="/" component={Receipt} />
          <Route path="/r/:id" component={Receipt} />
          <Route path="/hotspot/:id" component={Hotspot} />
          <Route exact path='/track/:code' component={Tracking} />
          <Route exact path='/s/:id' component={HotspotReceipt} />
        </>
      </Router>

      <LoadScript
          googleMapsApiKey={`${process.env.REACT_APP_GOOGLE_API_KEY}`}
          libraries={["places", "drawing"]}
          loadingElement={<div style={{display:"none"}}></div>}
        ></LoadScript>
    </ThemeContext.Provider>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
