import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom'
// import { ApiService } from '../../service/api.service';
import { Booking, BookingStatus } from '../../entities/booking.entity';
// import { Footer } from '../footer/Footer';
import Blink from "react-blink-text";
import { Driver } from '../../entities/driver.entity';
import { Vehicle } from '../../entities/vehicle.entity';
import car from '../../img/car.svg'
import { Api, Endpoint } from '../../services/api.service';
import { getStorageUrl } from '../../util/file.util';

const MAP_LIBS: Libraries = ['places'];

interface Gps {
    latitude: number,
    longitude: number,
    heading: number,
}
interface location {
    lat: number,
    lng: number
}

export const Tracking = () => {
    let { code }: any = useParams();
    const [map, setMap] = useState<google.maps.Map>()
    const [center, setCenter] = useState<google.maps.LatLng>();
    const [pickupPlace, setPickupPlace] = useState<location>();
    const [dropoffPlace, setDropoffPlace] = useState<location>();
    const [zoom, setZoom] = useState(10);
    const [booking, setBooking] = useState<Booking>();
    const [driverGps, setDriverGps] = useState<Gps>();
    const [point, setPoint] = useState<google.maps.Point>();
    const [size, setSize] = useState<google.maps.Size>();
    const [imgSrc, setImgSrc] = useState<string>();
    const history = useHistory();
    const [driverStatus,setDriverStatus] = useState<boolean>(false);
    const [count, setCount] = useState<number>(0);
    const { t } = useTranslation("dashboard");


    const getProfilePic = (driver: Driver): string => {
        if (driver.picFile) {
          return `${getStorageUrl()}/driver/${driver.id}/pic/${driver.picFile?.id
            }/admin_single.${driver.picFile?.extension}`;
        }
        return'';
      };
    const onLoad = React.useCallback((map) => {
        const bounds = new window.google.maps.LatLngBounds();
        map.fitBounds(bounds);
        setMap(map)
        setTimeout(() => {
            // Sydney
            const DEFAULT_CENTER = new google.maps.LatLng(-33.8688, 151.2093);
            const DEFAULT_POINT = new google.maps.Point(32, 32);
            const DEFAULT_SIZE = new google.maps.Size(64, 64);
            setCenter(DEFAULT_CENTER);
            setPoint(DEFAULT_POINT)
            setSize(DEFAULT_SIZE)
            setCenter(DEFAULT_CENTER);
            setZoom(5);
        refresh();
        }, 100);

    }, [])

    

    useEffect(() => {

        const intervalId = setInterval(() => { 
           
             //assign interval to a variable to clear it.
             if(count<6){
            if (booking?.status == BookingStatus.Dropoff || booking?.status == BookingStatus.Pickup || booking?.status == BookingStatus.AwaitingAccept || booking?.status == BookingStatus.DriverEnroute || booking?.status == BookingStatus.DestinationEnroute || booking?.status == BookingStatus.Arrived) {

                if (booking?.status == BookingStatus.Dropoff || booking?.status == BookingStatus.Pickup || booking?.status == BookingStatus.AwaitingAccept ){

                    setCount(count+1)

                }
                else{
                    setCount(0)
                }
                
              
               
                refresh(); 
            }
            

        }
        else{
            alert("Sorry, no driver was available in your area")
            //             // setDriverStatus(true)
                        history.push('/')
        }
       
        }, 10000)

        return () => clearInterval(intervalId); //This is important
    })

    const gpslatest = async (booking: Booking) => {
        try{
        const data:any = await Api.get(Endpoint.GPS_LATEST,{plate:(booking?.session?.vehicle as Vehicle).plate})
        console.log('This is the GPS Data:', data);
        if (data) {
            setImgSrc(getProfilePic(data.driver));
            setDriverGps(data?.gps);
            const DEFAULT_CENTER = new google.maps.LatLng(data?.gps.latitude, data?.gps?.longitude);
            setCenter(DEFAULT_CENTER);
        }
    }
    catch(err){
        console.log("error in gpslatest",err);
    }
    }

    let timer:any;

    // useEffect(()=>{
    //     console.log("gpslatest",gpslatest)
    //     console.log("driverStatus",driverStatus)

    //      timer =setTimeout(function(){

    //         console.log("driverStatus",driverStatus)

    //         // if(!driverStatus){
    //             alert("Sorry, no driver was available in your area")
    //             // setDriverStatus(true)
    //             history.push('/')

    //         // }

    //     },20000)
    // },[])


   

    const refresh = async () => {
        const data:any  = await Api.get(Endpoint.BOOKING_BY_CODE,{ code})
        // const data = response.items
        setBooking(data);
        setPickupPlace({ lat: data?.pickupAddress?.lat, lng: data?.pickupAddress?.lng })
        setDropoffPlace({ lat: data?.dropOffAddress?.lat, lng: data?.dropOffAddress?.lng })
        // setDriverStatus(true)
        // if(data){
        // window.clearTimeout(timer);
        // }
        // else{
        //     timer=setTimeout(function(){

        //         console.log("driverStatus",driverStatus)
    
        //         // if(!driverStatus){
        //             alert("Sorry, no driver was available in your area")
        //             // setDriverStatus(true)
        //             history.push('/')
    
        //         // }
    
        //     },20000)
        // }
        if (data?.status == BookingStatus.DriverEnroute || data?.status == BookingStatus.DestinationEnroute || data?.status == BookingStatus.Arrived) {
            
            gpslatest(data)
            
        }
    }

    const onUnmount = React.useCallback(() => {
        setMap(undefined)
    }, []);

    return (<>
     <div
      className={ `${process.env.REACT_APP_NAME}-body`}
      >
        <div className="logo alignCenter">
          <Row className={`${process.env.REACT_APP_NAME?.toLowerCase()}-logo`}>
            <Col xs lg="1">
              <img
                className="logoImg"
                src={`/logo/${process.env.REACT_APP_NAME?.toLowerCase()}.png`}
                alt="logo"
                style={{ width: "20rem" }}
              />
            </Col>

            <Col xs lg="1" className="col-mt">
              <h2
                className={`${process.env.REACT_APP_NAME?.toLowerCase()}-heading`}
              >
                {t("dashboard.thanks")}
                {process.env.REACT_APP_NAME}
              </h2>
            </Col>
          </Row>
        </div>
        
        
            
            {(booking?.status == BookingStatus.Dropoff || booking?.status == BookingStatus.Pickup || booking?.status == BookingStatus.AwaitingAccept) ?
             <Row>
              <Col>  <div style={{ textAlign: 'center', fontSize: 50, padding: 50 }}>
                    <Blink color='black' text='Waiting for Driver...' fontSize='50'>
                        Testing the Blink
                    </Blink>
                     </div> </Col>
                     </Row>
                     :
                    <Row style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                        marginTop: "50px"
 
                    }}>
               
               
                   
                         <Col style={{
                            flex: "1 1 30%",
                            height: "600px",
                            padding: "21px"
                      
                         }}>

                         <div style={{border:"1px solid #f5a106", height:"250px" , backgroundColor:"antiquewhite" ,
    marginBottom: "50px"
}} >
                            <div className="left-section">
                           
                                <img src={imgSrc} height='175px' width='33%'></img>
                                <div style={{marginLeft:"32px"}}>
                                <h5>{(booking?.session?.driver as Driver)?.firstName} {(booking?.session?.driver as Driver)?.lastName}</h5>
                             
                                <p>{(booking?.session?.vehicle as Vehicle)?.year} {(booking?.session?.vehicle as Vehicle)?.make} {(booking?.session?.vehicle as Vehicle)?.model}</p>
                                <p style={{color:"black"}}>COLOR: {(booking?.session.vehicle as Vehicle)?.color}</p>
                                <p>{booking?.code}</p>
                                </div>
                            </div>
                            <div className="">
                               
                            </div>
                            {/* <img src={smallImg1} alt="Gallery 1" className="gallery__img" /> */}
                        </div>
                        <div style={{border:"1px solid orange", height:"250px", background: "antiquewhite" , textAlign:"center"}}>
                          <div className="gallery__item gallery__item--3 container" style={{    marginTop: "43px"
}} >
                            {booking?.status == BookingStatus.DriverEnroute ?
                                <><p>Please wait</p>
                                    <h1>Driver is on its way</h1></> : booking?.status == BookingStatus.Arrived ?
                                    <><p>Thanks for being patient</p>
                                        <h1>Destination has arrived</h1></> : booking?.status == BookingStatus.DestinationEnroute ?
                                        <><p>This ride has began</p>
                                            <h1>Moving towards destination</h1></> : booking?.status == BookingStatus.PassengerCancelled ?
                                            <><p>This ride has been cancelled by passenger</p>
                                                <h1>Ride Cancelled </h1></> : booking?.status == BookingStatus.DriverCancelled ?
                                                <><p>This ride has been cancelled by driver</p>
                                                    <h1>Ride cancelled </h1></> : booking?.status == BookingStatus.Abandoned ?
                                                    <><p>This ride was abandoned</p>
                                                        <h1>Ride abandoned </h1></> : booking?.status == BookingStatus.Completed ?
                                                        <><p>This ride has been completed</p>
                                                            <h1>Thanks for riding with us </h1></> : booking?.status == BookingStatus.NotAccepted ?
                                                            <><p>This ride was not accepted</p>
                                                                <h1>No driver found in your area</h1></> : <></>}
                            <p>SUPPORT: +1300 864 222</p>
                        </div>
                            {/* <img src={smallImg1} alt="Gallery 1" className="gallery__img" /> */}
                        </div>
                </Col>
                <Col style={{flex:"30%"}}>
                <div className="main" >
                            {/* <div className="main-map"> */}
                            <GoogleMap
                                // mapContainerClassName='mapPosition'
                                mapContainerStyle={{ width: "98%",
                                    height: "600px",
                                    position: "relative",
                                    overflow: "hidden", }}
                                center={center}
                                zoom={zoom}
                                onLoad={onLoad}
                                onUnmount={onUnmount}
                            >
                                {driverGps ? <Marker key={driverGps.latitude} position={{ lat: driverGps.latitude, lng: driverGps.longitude }} zIndex={1000000000} icon={{
                                    url: car,
                                    scaledSize: size,
                                    anchor: point,
                                    rotation: driverGps.heading
                                }} visible={true} /> : null
                                }
                                {pickupPlace && <Marker position={pickupPlace} />}
                                {dropoffPlace && <Marker position={dropoffPlace} />}
                            </GoogleMap>

                            {/* </div> */}
                        </div>
                
                </Col>
                        
                      
                        
                   
                    </Row>
           
                }
        
        
       
        </div>
        
        {/* <Footer /> */}
    </>
    )
}